<template>
  <div class="edit-stock">
    <!-- 標題 -->
    <div class="single-page-tag">
      <p class="sp-start sp-text fw-bolder">編輯庫存</p>
    </div>
    <!-- 內容 -->
    <div class="tw-container shadow border border-1 border-dark mt-4">
      <div class="mb-4" style="height: calc(100vh - 200px); overflow-y: scroll">
        <!-- 新增入庫 -->
        <div class="mb-3">
          <p class="tw-border-start fw-bolder tw-text-size20 mb-3 ms-3">
            新增入庫
          </p>
          <table class="table mt-2">
            <thead>
              <tr>
                <th>庫存 ID</th>
                <th style="min-width: 150px">入庫款式</th>
                <th>成本</th>
                <th>庫存</th>
                <th>缺貨</th>
                <th style="min-width: 100px">入庫數量</th>
                <th style="min-width: 120px">操作</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{ stockList.id }}</td>
                <td>{{ stockList.merchandiseStyle }}</td>
                <td>{{ stockList.cost }}</td>
                <td>{{ stockList.quantity }}</td>
                <td>{{ stockList.outOfStockQuantity }}</td>
                <td>
                  <input
                    onwheel="this.blur()"
                    type="number"
                    min="0"
                    class="form-control d-inline-block"
                    placeholder="輸入入庫數量"
                    v-model="stockQty"
                  />
                </td>
                <td>
                  <button
                    class="btn-sm tw-btn tw-btn-success me-2 mb-1"
                    @click="addStockQuantity(stockList.quantity, true)"
                  >
                    入庫並配單
                  </button>
                  <button
                    class="btn-sm tw-btn tw-btn-success"
                    @click="addStockQuantity(stockList.quantity, false)"
                  >
                    入庫
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- 已叫貨入庫 -->
        <div>
          <p class="tw-border-start fw-bolder tw-text-size20 mb-3 ms-3">
            已叫貨入庫
          </p>
          <p class="text-danger fw-bold" v-if="!stockList.havePurchaseOrder">
            目前尚未有任何叫貨單!
          </p>
          <template v-else>
            <table width="50%" class="table">
              <thead>
                <tr>
                  <th scope="col">叫貨明細編號</th>
                  <th scope="col">供應商</th>
                  <th scope="col">已入庫 / 叫貨數</th>
                  <th scope="col">叫貨日期</th>
                  <th scope="col">入庫數量</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="orderBody in stockList.purchaseOrderBodies"
                  :key="orderBody.id"
                >
                  <td>{{ orderBody.id }}</td>
                  <td>{{ orderBody.purchaseOrderHeader.supplier.name }}</td>
                  <td>
                    {{ orderBody.stockInQuantity }}/{{ orderBody.quantity }}
                  </td>
                  <td>{{ orderBody.purchaseOrderHeader.createTime }}</td>
                  <td>
                    <input
                      onwheel="this.blur()"
                      type="number"
                      min="0"
                      class="form-control"
                      placeholder=""
                      v-model="orderBody.addStockInQuantity"
                    />
                  </td>
                </tr>
                <tr>
                  <td class="text-end" colspan="5">
                    <button
                      class="tw-btn tw-btn-success me-2"
                      @click="updatePurchaseOrder(true)"
                    >
                      叫貨單入庫並配單
                    </button>
                    <button
                      class="tw-btn tw-btn-success"
                      @click="updatePurchaseOrder(false)"
                    >
                      叫貨單入庫
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </template>
        </div>
      </div>

      <div class="text-end">
        <router-link
          :to="`/seller/store/${storeId}/stock`"
          class="tw-btn tw-btn-secondary border border-2 border-dark mb-3 mx-1"
          >返回</router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // data
      serverToken: '',
      storeId: 0,
      stockId: 0,
      stockList: {},
      stockQty: 0,
    }
  },
  created() {
    this.initialization()
    this.getStockInfo()
  },
  methods: {
    // 初始化
    initialization() {
      this.serverToken = this.$methods.getCookie('serverToken')
      this.storeId = this.$route.params.storeId
    },
    // 取得庫存資訊
    getStockInfo() {
      const vm = this
      this.$methods.switchLoading('show')
      const getStockInfoApi = `${process.env.VUE_APP_API}/search/getSiteObjects`
      const token = this.serverToken;
      const header = {
        authorization: token,
      };
      this.stockId = this.$route.query.id ? this.$route.query.id : null
      const data = [
        {
          type: 24,
          ids: [this.stockId],
          methods: '{getOutOfStockQuantity,getMerchandiseStyles,getPurchaseOrderBodies{getPurchaseOrderHeader{getSupplier}}}'
        },
      ];
      $.ajax({
        type: 'POST',
        async: true,
        url: getStockInfoApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            const stockList = res.data[0].objects
            vm.stockMap(stockList)
          }
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
          this.dataTabelLoading = false
        },
      })
    },
    // 整理庫存列表
    stockMap(stockList) {
      stockList.forEach(item => {
        const object = {
          cost: item.merchandiseStyles[0].cost,
          havePurchaseOrder: item.purchaseOrderBodies.length > 0 ? true : false,
          id: item.id,
          limitedQuantity: item.limitedQuantity,
          merchandiseId: item.merchandiseId,
          merchandiseStyle: '',
          merchandiseStyles: item.merchandiseStyles,
          outOfStockQuantity: item.outOfStockQuantity,
          purchaseOrderBodies: item.purchaseOrderBodies,
          quantity: item.quantity,
        }
        // 需特別處理
        // 訂單款式
        let merchandiseStyle = ''
        object.merchandiseStyles.forEach(item =>{
          merchandiseStyle =  merchandiseStyle + item.name
        })
        object.merchandiseStyle = merchandiseStyle
        // 叫貨日期 & 新增入庫數量變數
        object.purchaseOrderBodies.forEach(item => {
          item.purchaseOrderHeader.createTime = this.$methods.moment(item.purchaseOrderHeader.createTime).format("YYYY-MM-DD HH:mm:ss")
          item.addStockInQuantity = 0;
        })
        this.stockList = JSON.parse(JSON.stringify(object))
      })
      console.log(this.stockList)
      this.$methods.switchLoading('hide')
      this.dataTabelLoading = false
    },
    // 庫存入庫
    addStockQuantity(stockQuantity, allocate) {
      const vm = this;
      this.$methods.switchLoading('show')
      const addStockQuantityApi = `${process.env.VUE_APP_API}/stock/update`;
      const token = this.serverToken;
      const header = {
        authorization: token,
      };
      if (this.stockQty === 0 || !this.stockQty) {
        this.$methods.switchLoading('hide')
        return this.SweetAlert("other", "請輸入入庫數量");
      }
      let merchandiseStyleIds = []
      this.stockList.merchandiseStyles.forEach((item) => merchandiseStyleIds.push(item.id))
      const data = [
        {
          stock: {
            id: this.stockId,
            quantity: this.stockQty + stockQuantity,
            limitedQuantity: this.stockList.limitedQuantity
          },
          allocate: allocate
        }
      ]
      $.ajax({
        type: 'PUT',
        async: true,
        url: addStockQuantityApi,
        headers: header,
        data: JSON.stringify(data),
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            vm.SweetAlert(res.code, res.message)
            vm.stockQty = 0
            vm.getStockInfo()
          } else {
            vm.SweetAlert(res.code, res.message)
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          if (err.responseJSON.errorData.length > 0) {
            let retrunObject = {
              icon: 'warning',
              title: {
                show: true,
                value: '錯誤'
              },
              text: {
                show: true,
                value: ''
              },
              timer: 5000
            }
            err.responseJSON.errorData.forEach(item => {
              console.log(item)
              retrunObject.text.value += `${item.message}，庫存 ID 為 `
              item.errorData.forEach(data => {
                retrunObject.text.value += `${data.id} `
              })
              retrunObject.text.value += '\n'
            })
            vm.SweetAlert('setByMyself', retrunObject)
          } else {
            vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          }
          vm.$methods.switchLoading('hide')
        }
      })

    },
    // 叫貨單單入庫
    updatePurchaseOrder(allocate) {
      const vm = this;
      this.$methods.switchLoading('show')
      const updatePurchaseOrderApi = `${process.env.VUE_APP_API}/purchaseOrder/updateBody`;
      const header = {
        authorization: this.serverToken,
      };
      let arr = [];
      this.stockList.purchaseOrderBodies.forEach(item => {
        console.log(item)
        if (item.addStockInQuantity !== 0 && item.addStockInQuantity) {
          let obj = {
            id: item.id,
            cost: item.cost,
            quantity: item.quantity,
            stockInQuantity: item.addStockInQuantity,
            allocate: allocate
          };
          arr.push(obj);
        }
      })
      if (arr.length === 0) {
        this.$methods.switchLoading('hide')
        return this.SweetAlert("other", "請輸入入庫數量");
      }
      $.ajax({
        type: 'PUT',
        async: true,
        url: updatePurchaseOrderApi,
        headers: header,
        data: JSON.stringify(arr),
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            vm.SweetAlert(res.code, res.message)
            vm.getStockInfo()
          } else {
            vm.SweetAlert(res.code, res.message)
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
    }
  }
}
</script>